@font-face {
  font-family: 'futura_mdmedium';
  src: url("../../assets/fonts/ftramd-webfont.eot");
  src: url("../../assets/fonts/ftramd-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/ftramd-webfont.woff2") format("woff2"), url("../../assets/fonts/ftramd-webfont.woff") format("woff"), url("../../assets/fonts/ftramd-webfont.ttf") format("truetype"), url("../../assets/fonts/ftramd-webfont.svg#futura_mdmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'futura_bkbold';
  src: url("../../assets/fonts/ftrabd-webfont.eot");
  src: url("../../assets/fonts/ftrabd-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/ftrabd-webfont.woff2") format("woff2"), url("../../assets/fonts/ftrabd-webfont.woff") format("woff"), url("../../assets/fonts/ftrabd-webfont.ttf") format("truetype"), url("../../assets/fonts/ftrabd-webfont.svg#futura_bkbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

.headerFixed .sub-menu {
  display: none;
}

.marginT10 {
  margin-top: 10px;
}

.marginT20 {
  margin-top: 20px;
}

.marginT30 {
  margin-top: 30px;
}

.marginT40 {
  margin-top: 40px;
}

.marginT50 {
  margin-top: 50px;
}

.paddingT10 {
  padding-top: 10px;
}

.paddingT20 {
  padding-top: 20px;
}

.paddingT30 {
  padding-top: 30px;
}

.paddingT40 {
  padding-top: 40px;
}

.paddingT50 {
  padding-top: 50px;
}

.marginB10 {
  margin-bottom: 10px;
}

.marginB20 {
  margin-bottom: 20px;
}

.marginB30 {
  margin-bottom: 30px;
}

.marginB40 {
  margin-bottom: 40px;
}

.marginB50 {
  margin-bottom: 50px;
}

.paddingB10 {
  padding-bottom: 10px;
}

.paddingB20 {
  padding-bottom: 20px;
}

.paddingB30 {
  padding-bottom: 30px;
}

.paddingB40 {
  padding-bottom: 40px;
}

.paddingB50 {
  padding-bottom: 50px;
}

body {
  font-family: "Muli", sans-serif;
  font-size: 14px;
  color: #575757;
  line-height: normal;
  font-weight: 400;
}

.stopScrolling {
  height: 100%;
  overflow: hidden;
}

.pColor {
  color: #575757 !important;
}

.tos-desktop .tos-wrapper.tos-fixed {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.tos-wrapper .tos-close {
  border-radius: 0;
  border: solid 1px #fff;
  background-color: transparent;
  background-image: url(../img/lightbox-cerrar.png);
  background-position: center;
  background-repeat: no-repeat;
}

.tos-wrapper .tos-close span {
  display: none;
}

.tos-wrapper .tos-prev,
.tos-wrapper .tos-next {
  border-radius: 0;
  height: 40px;
  margin-top: -20px;
  border: solid 1px #fff;
  background-color: transparent;
}

.tos-wrapper .tos-prev span,
.tos-wrapper .tos-next span {
  display: none;
}

.tos-wrapper .tos-prev {
  background-image: url(../img/lightbox-left.png);
  background-position: center;
  background-repeat: no-repeat;
}

.tos-wrapper .tos-next {
  background-image: url(../img/lightbox-right.png);
  background-position: center;
  background-repeat: no-repeat;
}

.tos-wrapper .tos-pagination a {
  border: solid 1px #fff;
}

.mainContainer {
  position: relative;
  left: 0;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

.mainContainer.moveMainCont {
  left: calc(60px - 100%);
}

.page-template-quienes-somos .pageTitle {
  margin-bottom: 0;
}

.page-template-quienes-somos .aboutTitle {
  font-size: 42px;
  color: #333333;
  font-weight: 900;
  margin-bottom: 20px;
}

.page-template-quienes-somos .mainContent {
  overflow: hidden;
}

.page-template-quienes-somos .mainContent blockquote {
  max-width: 570px;
  float: left;
  font-size: 15px;
  line-height: normal;
  margin-top: 35px;
}

.page-template-quienes-somos .aboutSecundario {
  position: relative;
  overflow: hidden;
  margin-bottom: 55px;
}

.page-template-quienes-somos .aboutSecundario span {
  font-size: 144px;
  font-family: "Muli", sans-serif;
  font-weight: 200;
  color: #f2f2f2;
  position: absolute;
  z-index: 0;
}

.page-template-quienes-somos .aboutSecundario p {
  position: relative;
  z-index: 1;
}

.page-template-quienes-somos .aboutSecundario p:last-child {
  margin-bottom: 0;
}

.page-template-quienes-somos .aboutSecundario strong strong {
  font-size: 18px;
}

.page-template-quienes-somos .aboutSecundario.itemN01 {
  opacity: 1 !important;
}

.page-template-quienes-somos .aboutSecundario.itemN01 div {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 630px;
  background-color: #fff;
  padding: 25px 20px 0;
}

.page-template-quienes-somos .aboutSecundario.itemN01 div span {
  right: 0;
  top: -100px;
}

.page-template-quienes-somos .aboutSecundario.itemN02 {
  text-align: right;
}

.page-template-quienes-somos .aboutSecundario.itemN02 div {
  position: absolute;
  right: 45%;
  margin-top: -98px;
  top: 50%;
  width: 550px;
  background-color: #fff;
  padding: 25px 20px;
}

.page-template-quienes-somos .aboutSecundario.itemN02 div span {
  left: 0;
  top: 80px;
}

.page-template-quienes-somos .aboutSecundario.itemN03 {
  padding-bottom: 100px;
}

.page-template-quienes-somos .aboutSecundario.itemN03 img {
  margin-left: 70px;
}

.page-template-quienes-somos .aboutSecundario.itemN03 div {
  position: absolute;
  right: 70px;
  bottom: 50px;
  width: 550px;
  background-color: #fff;
  padding: 25px 20px 0;
}

.page-template-quienes-somos .aboutSecundario.itemN03 div span {
  right: -45px;
  bottom: -80px;
}

.panelLateral {
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
  background-color: #2D2D2D;
  color: #fff;
  position: fixed;
  width: calc(100% - 60px);
  left: 100%;
  height: 100%;
  top: 0;
}

.panelLateral .menuPanelLateral {
  padding-top: 20px;
  max-height: 100%;
  overflow: scroll;
}

.panelLateral .menuPanelLateral .itemPadre.active > a {
  background-color: #595959;
  color: #F5A623;
}

.panelLateral .menuPanelLateral li {
  position: relative;
}

.panelLateral .menuPanelLateral .showMovilSubMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  text-align: center;
  line-height: 42px;
  z-index: 9;
}

.panelLateral .menuPanelLateral a {
  font-family: "Work Sans", sans-serif;
  color: #fff;
  font-weight: 300;
  display: block;
  padding-left: 25px;
  text-transform: uppercase;
  line-height: 1.5em;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.panelLateral .menuPanelLateral a:hover, .panelLateral .menuPanelLateral a:focus {
  color: #F5A623;
  text-decoration: none;
}

.panelLateral .menuPanelLateral .backToHome {
  margin-bottom: 10px;
  font-size: 15px;
  color: #aaa;
}

.panelLateral .menuPanelLateral .backToHome i {
  display: inline-block;
  font-size: 32px;
  line-height: 32px;
  vertical-align: middle;
  margin-right: 8px;
}

.panelLateral .menuPanelLateral ul {
  padding: 0;
  list-style-type: none;
}

.panelLateral .menuPanelLateral ul .menu-item-has-children .menu-item-has-children a {
  margin-bottom: 0;
}

.panelLateral .menuPanelLateral ul li a {
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.panelLateral .menuPanelLateral ul .primerHijo {
  display: none;
}

.panelLateral .menuPanelLateral ul .sub-menu li a {
  font-weight: 500;
  font-size: 13px;
  background-color: #797979;
  color: #CFCFCF;
}

.panelLateral .menuPanelLateral ul .sub-menu .sub-menu {
  padding-left: 0;
}

.panelLateral .menuPanelLateral ul .sub-menu .sub-menu li a {
  text-transform: none;
  background-color: #595959;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
  font-size: 12px;
  font-weight: 400;
}

.panelLateral .menuPanelLateral ul .sub-menu .sub-menu li a:before {
  content: '\f21b';
  display: inline-block;
  font-family: 'Ionicons';
  margin-right: 10px;
  font-size: 6px;
  color: #d8d8d8;
  vertical-align: middle;
}

.panelLateral.movePanelLateral {
  left: 60px;
}

.dropdown-menu {
  z-index: 99999;
}

.relativa {
  position: relative;
}

.hideme,
.displayRight,
.displayLeft {
  opacity: 0;
}

/*
.preload {
    background-color: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    left: 0;
    top: 0;
}
*/
.menuMask {
  position: absolute;
  left: 0;
  top: 148px;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 31, 41, 0.9);
  z-index: 995;
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

p {
  margin-bottom: 20px;
}

ul {
  padding: 0;
  margin: 0;
}

blockquote {
  font-size: 16px;
  font-family: "Muli", sans-serif;
  line-height: 1.5em;
  color: #575757;
  border-left: solid 2px #FE8A52;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 30px;
  margin-bottom: 20px;
}

blockquote p:last-child {
  margin-bottom: 0;
}

.alignleft {
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
}

.alignright {
  float: right;
  margin-left: 20px;
  margin-bottom: 10px;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.stopScrolling {
  height: 100%;
  overflow: hidden;
}

.contactTab {
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
  display: block;
  width: 55px;
  height: 120px;
  background-color: #5BBDE8;
  border-left: solid 3px #3B3B3B;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -60px;
  z-index: 997;
  background-image: url(../img/contacto.png);
  background-position: center;
  background-repeat: no-repeat;
}

.contactTab:hover {
  cursor: pointer;
}

.contactTab.active {
  right: 310px;
  opacity: 1 !important;
}

.contactTabContainer {
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
  background-color: #fff;
  padding: 10px 20px 20px;
  padding-left: 30px;
  position: fixed;
  right: -310px;
  top: 50%;
  height: 420px;
  overflow: scroll;
  margin-top: -210px;
  z-index: 996;
  max-width: 310px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.contactTabContainer .singleCampo,
.contactTabContainer textarea {
  width: 100%;
}

.contactTabContainer .col-sm-6 {
  width: 100%;
}

.contactTabContainer small {
  font-size: 10px;
  color: #D0011B;
}

.contactTabContainer.active {
  right: 0;
  opacity: 1 !important;
}

.contactTabContainer form .col-xs-12 {
  margin-top: 10px;
}

.contactTabContainer form .singleCampo {
  border: solid 1px #E1E1E1;
  padding: 5px 10px;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.contactTabContainer form .singleCampo:focus {
  outline: none;
  border: 1px solid #FE8A52;
}

.contactTabContainer form .labelForm {
  padding-left: 20px;
  padding-bottom: 2px;
  display: inline-block;
}

.contactTabContainer form .labelForm small {
  font-size: 14px;
  vertical-align: top;
  margin-left: 2px;
}

.contactTabContainer form textarea {
  padding: 5px 10px;
  height: 120px;
  max-height: 120px;
  min-height: 120px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: solid 1px #E1E1E1;
}

.contactTabContainer form textarea:focus {
  outline: none;
}

.contactTabContainer form .wpcf7-submit {
  padding: 8px 25px;
  background-color: #5BBDE8;
  border: 1px solid #5BBDE8;
  color: #fff;
  border-radius: 4px;
  margin: 0;
  font-family: "Muli", sans-serif;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.contactTabContainer form .wpcf7-submit:hover, .contactTabContainer form .wpcf7-submit:focus {
  color: #5BBDE8;
  outline: none;
  background-color: #fff;
}

.contactTabContainer form .ajax-loader {
  display: none !important;
}

.contactTabContainer form .colNombre:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -3px;
  top: 0;
  z-index: -1;
  background-image: url(../img/form-nombre.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 33px;
}

.contactTabContainer form .colEmail:before {
  content: '';
  display: block;
  width: 49px;
  height: 36px;
  position: absolute;
  left: -3px;
  top: 0;
  z-index: -1;
  background-image: url(../img/form-mail.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 33px;
}

.contactTabContainer form .colTelefono:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -3px;
  top: 0;
  z-index: -1;
  background-image: url(../img/form-telefono.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 33px;
}

.contactTabContainer form .colMensaje:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -3px;
  top: 0;
  z-index: -1;
  background-image: url(../img/form-mensaje.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 33px;
}

.contactTabContainer form span.wpcf7-not-valid-tip {
  font-size: 12px;
  font-weight: 700;
  margin-top: 3px;
}

.contactTabContainer form div.wpcf7-validation-errors,
.contactTabContainer form div.wpcf7-mail-sent-ok {
  background-color: red;
  text-align: center;
  border: none;
  width: 100%;
  margin: 10px 0 0;
  padding: 10px;
  color: #fff;
  font-size: 12px;
}

.contactTabContainer form div.wpcf7-mail-sent-ok {
  background-color: #FE8A52;
}

.home .contactTab {
  opacity: 0;
}

.pageTitle {
  font-family: "Muli", sans-serif;
  font-size: 42px;
  color: #2D2D2D;
  font-weight: 900;
  margin-bottom: 30px;
}

.pageTitle span {
  font-weight: 300;
  display: block;
}

.minTitle {
  font-weight: bold;
  font-size: 25px;
  color: #090909;
  margin-bottom: 20px;
}

.minTitle span {
  font-weight: normal;
}

.whiteBton {
  font-size: 14px;
  font-family: "Muli", sans-serif;
  font-weight: 600;
  color: #575757;
  border-radius: 6px;
  border: solid 1px #575757;
  display: inline-block;
  padding: 8px 20px;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.whiteBton:hover, .whiteBton:focus {
  text-decoration: none;
  color: #fff;
  background-color: #575757;
}

.whiteColor {
  color: #fff;
}

.acf-map {
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}

.headerFixed {
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  position: fixed;
  left: 0;
  top: -70px;
  width: 100%;
  background-color: #fff;
  border-bottom: solid 1px #ccc;
  z-index: 998;
}

.headerFixed #menu-menu-principal {
  list-style-type: none;
  font-size: 0px;
  text-transform: uppercase;
  font-family: "Work Sans", sans-serif;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.headerFixed #menu-menu-principal > li > .sub-menu > li > a {
  max-width: 155px;
}

.headerFixed #menu-menu-principal > li {
  display: inline-block;
  margin-right: 50px;
  text-align: left;
  vertical-align: top;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.headerFixed #menu-menu-principal > li.current_page_item a:after, .headerFixed #menu-menu-principal > li.current-page-ancestor a:after {
  width: 50px;
}

.headerFixed #menu-menu-principal > li:last-child {
  border: solid 1px #3B3B3B;
  background-color: #f3f3f3;
  padding: 8px 20px;
  border-radius: 5px;
  max-width: inherit;
  margin-right: 0;
}

.headerFixed #menu-menu-principal > li:last-child a:after {
  display: none;
}

.headerFixed #menu-menu-principal > li:last-child:hover {
  background-color: #555;
}

.headerFixed #menu-menu-principal > li:last-child:hover a {
  color: #fff;
}

.headerFixed #menu-menu-principal > li a {
  color: #575757;
  font-weight: 500;
  font-size: 15px;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  display: block;
}

.headerFixed #menu-menu-principal > li a:after {
  content: '';
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  display: block;
  width: 0;
  height: 1px;
  background-color: black;
  margin-top: 2px;
}

.headerFixed #menu-menu-principal > li a:hover, .headerFixed #menu-menu-principal > li a:focus {
  color: #000;
  text-decoration: none;
}

.headerFixed #menu-menu-principal > li a:hover:after, .headerFixed #menu-menu-principal > li a:focus:after {
  width: 50px;
}

.headerFixed #menu-menu-principal > .sub-menu {
  right: -130px;
}

.headerFixed #menu-menu-principal .sub-menu {
  display: none;
  right: 0;
  top: 138px;
  width: 720px;
  position: absolute;
  background-color: #fff;
  padding: 20px;
  padding-bottom: 5px;
}

.headerFixed #menu-menu-principal .sub-menu li {
  float: left;
}

.headerFixed #menu-menu-principal .sub-menu li a {
  font-size: 11px;
  font-weight: 800;
}

.headerFixed #menu-menu-principal .sub-menu .sub-menu {
  background: none;
  position: relative;
  top: inherit;
  padding-left: 20px;
  left: inherit;
  max-width: 220px;
}

.headerFixed #menu-menu-principal .sub-menu .sub-menu li {
  float: none;
  margin: 0;
  margin-bottom: 10px;
}

.headerFixed #menu-menu-principal .sub-menu .sub-menu li a {
  font-weight: 700;
}

.headerFixed #menu-menu-principal .itemPadre.current-menu-ancestor .sub-menu li a:after {
  width: 0;
}

.headerFixed #menu-menu-principal .itemPadre.current-menu-ancestor .sub-menu li a:hover:after {
  width: 50px;
}

.headerFixed #menu-menu-principal .menu-item-210 .itemN01,
.headerFixed #menu-menu-principal .menu-item-182 .itemN01,
.headerFixed #menu-menu-principal .menu-item-184 .itemN01,
.headerFixed #menu-menu-principal .menu-item-211 .itemN01 {
  font-weight: 700;
  display: block;
}

.headerFixed #menu-menu-principal .menu-item-183 .itemN01 {
  font-weight: 700;
}

.headerFixed #menu-menu-principal .menu-item-183 .itemN03:before {
  content: '';
  display: block;
}

.headerFixed img {
  margin-top: 5px;
}

header {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 999;
}

header h1 {
  margin: 0;
}

header h1 a {
  background-repeat: no-repeat;
  background-position: center;
  width: 151px;
  height: 128px;
  text-indent: -9999px;
  display: block;
}

header .supHeaderContainer {
  font-size: 14px;
  color: #757575;
  font-family: "Muli", sans-serif;
  margin-bottom: 30px;
  margin-top: 10px;
}

header .supHeaderContainer a {
  font-size: 14px;
  color: #757575;
  text-decoration: none;
}

header .supHeaderContainer i {
  color: #cfcfcf;
  font-size: 24px;
  vertical-align: middle;
}

header .supHeaderContainer span {
  display: inline-block;
  border-right: solid 1px #cfcfcf;
  padding-right: 10px;
  margin-right: 10px;
}

header #menu-menu-principal-1 {
  list-style-type: none;
  font-size: 0px;
  text-transform: uppercase;
  font-family: "Work Sans", sans-serif;
  overflow: hidden;
}

header #menu-menu-principal-1 > li > .sub-menu > li > a {
  max-width: 155px;
}

header #menu-menu-principal-1 > li {
  display: inline-block;
  margin-right: 50px;
  text-align: left;
  vertical-align: top;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

header #menu-menu-principal-1 > li.current_page_item a:after, header #menu-menu-principal-1 > li.current-page-ancestor a:after {
  width: 50px;
}

header #menu-menu-principal-1 > li:last-child {
  border: solid 1px #3B3B3B;
  background-color: #f3f3f3;
  padding: 8px 20px;
  border-radius: 5px;
  max-width: inherit;
  margin-right: 0;
}

header #menu-menu-principal-1 > li:last-child a:after {
  display: none;
}

header #menu-menu-principal-1 > li:last-child:hover {
  background-color: #555;
}

header #menu-menu-principal-1 > li:last-child:hover a {
  color: #fff;
}

header #menu-menu-principal-1 > li a {
  color: #575757;
  font-weight: 500;
  font-size: 15px;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  display: block;
}

header #menu-menu-principal-1 > li a:after {
  content: '';
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  display: block;
  width: 0;
  height: 1px;
  background-color: black;
  margin-top: 2px;
}

header #menu-menu-principal-1 > li a:hover, header #menu-menu-principal-1 > li a:focus {
  color: #000;
  text-decoration: none;
}

header #menu-menu-principal-1 > li a:hover:after, header #menu-menu-principal-1 > li a:focus:after {
  width: 50px;
}

header #menu-menu-principal-1 > .sub-menu {
  right: -130px;
}

header #menu-menu-principal-1 .sub-menu {
  display: none;
  right: 0;
  top: 138px;
  width: 720px;
  position: absolute;
  background-color: #fff;
  padding: 20px;
  padding-bottom: 5px;
}

header #menu-menu-principal-1 .sub-menu li {
  float: left;
}

header #menu-menu-principal-1 .sub-menu li a {
  font-size: 11px;
  font-weight: 800;
}

header #menu-menu-principal-1 .sub-menu .sub-menu {
  background: none;
  position: relative;
  top: inherit;
  padding-left: 20px;
  left: inherit;
  max-width: 220px;
}

header #menu-menu-principal-1 .sub-menu .sub-menu li {
  float: none;
  margin: 0;
  margin-bottom: 10px;
}

header #menu-menu-principal-1 .sub-menu .sub-menu li a {
  font-weight: 700;
}

header #menu-menu-principal-1 .itemPadre.current-menu-ancestor .sub-menu li a:after {
  width: 0;
}

header #menu-menu-principal-1 .itemPadre.current-menu-ancestor .sub-menu li a:hover:after {
  width: 50px;
}

header #menu-menu-principal-1 .menu-item-210 .itemN01,
header #menu-menu-principal-1 .menu-item-182 .itemN01,
header #menu-menu-principal-1 .menu-item-184 .itemN01,
header #menu-menu-principal-1 .menu-item-211 .itemN01 {
  font-weight: 700;
  display: block;
}

header #menu-menu-principal-1 .menu-item-183 .itemN01 {
  font-weight: 700;
}

header #menu-menu-principal-1 .menu-item-183 .itemN03:before {
  content: '';
  display: block;
}

header #menu-menu-principal-1 li {
  list-style: none !important;
}

header #menu-menu-principal-1 > li:last-child {
  padding: 0 !important;
}

header #menu-menu-principal-1 > li:last-child a {
  padding: 9px 20px;
}

.headerFixed #menu-menu-principal > li:last-child {
  padding: 0 !important;
}

.headerFixed #menu-menu-principal > li:last-child a {
  padding: 9px 20px;
}

.carouselContainer {
  margin-bottom: 12px;
}

.carouselContainer .item {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.carouselContainer .item .container {
  height: calc(100vh - 148px);
  position: relative;
}

.carouselContainer .item h2 {
  font-family: "Muli", sans-serif;
  color: #fff;
  font-size: 72px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 75px;
  max-width: 830px;
  margin-bottom: 20px;
}

.carouselContainer .item p {
  font-family: "Muli", sans-serif;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 0;
}

.carouselContainer .item a {
  color: #fff;
}

.carouselContainer .item i {
  font-size: 56px;
}

.carouselContainer .item .carouselItem {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 50px;
}

.carouselContainer .carousel-indicators {
  left: inherit;
  margin-left: 0;
  width: 100%;
  margin-bottom: 0;
  text-align: right;
}

.carouselContainer .carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid 1px #fff;
  margin: 0;
  margin-left: 10px;
}

.prodServContainer {
  overflow: hidden;
  margin-bottom: 70px;
}

.prodServContainer .singleProdServ {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 275px;
  max-height: 275px;
  overflow: hidden;
  color: #fff;
}

.prodServContainer .singleProdServ.itemN01:hover .mask {
  background-color: rgba(237, 21, 145, 0.5);
}

.prodServContainer .singleProdServ.itemN02:hover .mask {
  background-color: rgba(74, 144, 226, 0.5);
}

.prodServContainer .singleProdServ.itemN03:hover .mask {
  background-color: rgba(255, 135, 0, 0.5);
}

.prodServContainer .singleProdServ .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  margin-left: -15px;
  margin-right: -15px;
  top: 0;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.prodServContainer .singleProdServ > div {
  position: relative;
  z-index: 99;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
}

.prodServContainer .singleProdServ h3 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 200;
  margin-bottom: 0;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.prodServContainer .singleProdServ h3 strong {
  font-weight: 700;
}

.prodServContainer .singleProdServ h3 a {
  color: #fff;
}

.prodServContainer .singleProdServ h3 a:hover {
  text-decoration: none;
}

.prodServContainer .singleProdServ h3 a i {
  font-size: 56px;
  display: block;
}

.prodServContainer .singleProdServ h3 p {
  margin-bottom: 0;
}

.contentContainerHome section {
  margin-bottom: 50px;
}

.contentContainerHome .nuestroCentrosContainer .sectionContent h2 {
  border: none;
}

.contentContainerHome .sectionContent {
  overflow: hidden;
  padding-bottom: 10px;
}

.contentContainerHome .sectionContent h2 {
  display: inline-block;
  margin-right: 50px;
  padding-right: 50px;
  border-right: solid 1px #cfcfcf;
  vertical-align: middle;
}

.contentContainerHome .sectionContent div {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

.contentContainerHome .pageTitle {
  line-height: 42px;
  margin-bottom: 15px;
}

.contentContainerHome .content {
  margin-left: 60px;
  overflow: hidden;
  font-size: 18px;
}

.contentContainerHome .content ul {
  list-style-type: none;
  float: left;
  margin-right: 150px;
}

.contentContainerHome .content ul:last-child {
  margin-right: 0;
}

.contentContainerHome .content ul li {
  padding-top: 10px;
  padding-bottom: 10px;
}

.contentContainerHome .content ul li span {
  display: inline-block;
  vertical-align: text-bottom;
  width: 45px;
  text-align: center;
}

.contentContainerHome .nuestroCentrosContainer {
  position: relative;
  z-index: 995;
}

.contentContainerHome .nuestroCentrosContainer:before {
  content: '';
  display: block;
  width: 228px;
  height: 253px;
  background-image: url(../img/letter-n.png);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: -1;
  left: -40px;
  top: -40px;
}

.contentContainerHome .nuestroCentrosContainer .dropdown button {
  background-color: transparent;
  border: solid 1px #575757;
  border-radius: 4px;
  width: 150px;
  text-align: left;
  padding: 7px 10px;
  background-color: #EBEBEB;
}

.contentContainerHome .nuestroCentrosContainer .dropdown button:focus {
  outline: none;
}

.contentContainerHome .nuestroCentrosContainer .dropdown button .caret {
  margin-right: auto;
}

.contentContainerHome .nuestroCentrosContainer .item {
  background-color: #EBEBEB;
  background-repeat: no-repeat;
  padding-top: 25px;
  min-height: 190px;
  background-position-y: center;
  background-position-x: 85%;
  padding-left: 20px;
  position: relative;
}

.contentContainerHome .nuestroCentrosContainer .item01 {
  padding-right: 1px;
}

.contentContainerHome .nuestroCentrosContainer .item02 {
  padding-left: 1px;
  padding-right: 1px;
}

.contentContainerHome .nuestroCentrosContainer .item03 {
  padding-left: 1px;
}

.contentContainerHome .nuestroCentrosContainer .item01 .item {
  border-top: solid 5px #F5A623;
  background-image: url(../img/map-sant.png);
}

.contentContainerHome .nuestroCentrosContainer .item02 .item {
  border-top: solid 5px #4990E2;
  background-image: url(../img/map-chile.png);
}

.contentContainerHome .nuestroCentrosContainer .item03 .item {
  border-top: solid 5px #ED1591;
  background-image: url(../img/map-lat.png);
}

.contentContainerHome .nuestroCentrosContainer .dropdown {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.contentContainerHome .nuestroCentrosContainer .dropdown button {
  position: relative;
}

.contentContainerHome .nuestroCentrosContainer .dropdown button .caret {
  position: absolute;
  margin: 0;
  right: 10px;
  top: 50%;
  margin-top: -2px;
}

.contentContainerHome .infraCont {
  position: relative;
  z-index: 99;
}

.contentContainerHome .infraCont:before {
  content: '';
  display: block;
  width: 203px;
  height: 224px;
  background-image: url(../img/letter-c.png);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: -1;
  left: -40px;
  top: -40px;
}

.contentContainerHome .centrosDistCont {
  position: relative;
}

.contentContainerHome .centrosDistCont:before {
  content: '';
  display: block;
  width: 203px;
  height: 224px;
  background-image: url(../img/letter-c.png);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: -1;
  left: -40px;
  top: -40px;
}

.contentContainerHome .centrosDistCont .centCarouselCont {
  position: relative;
}

.contentContainerHome .centrosDistCont .centCarouselCont a.linkMask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  color: #fff;
  text-decoration: none;
}

.contentContainerHome .centrosDistCont .centCarouselCont a.linkMask:hover span {
  opacity: 1;
}

.contentContainerHome .centrosDistCont .centCarouselCont a.linkMask span {
  display: block;
  text-align: center;
  top: 50%;
  margin-top: -20px;
  font-size: 15px;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  opacity: 0;
}

.contentContainerHome .centrosDistCont .centCarouselCont a.linkMask span img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.contentContainerHome .centrosDistCont .centCarouselCont img {
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.contentContainerHome .centrosDistCont .centCarouselCont .relativa:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.contentContainerHome .centrosDistCont .centCarouselCont .imgCont {
  margin-bottom: 10px;
  overflow: hidden;
}

.contentContainerHome .centrosDistCont .centCarouselCont .contCont {
  padding-bottom: 5px;
}

.contentContainerHome .centrosDistCont .centCarouselCont .contCont h2 {
  font-size: 21px;
  font-weight: 800;
  margin-bottom: 0;
  color: #2D2D2D;
}

.contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav {
  position: absolute;
  right: -45px;
  top: 118px;
}

.contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav a {
  display: block !important;
  width: 48px;
  height: 48px;
  font-size: 32px;
  text-align: center;
  padding-top: 4px;
  position: relative;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav a#prevCent {
  background-color: #575757;
  border: solid 1px #575757;
  color: #fff;
}

.contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav a#nextCent {
  background-color: #fff;
  border: solid 1px #575757;
  color: #5BBDE8;
}

.contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav a:hover {
  z-index: 999;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
}

.contentContainerHome .clientesCont {
  margin-bottom: 0;
}

.contentContainerHome .clientesCont h2 span {
  display: inline;
}

.contentContainerHome .clientesCont .clientesCarouselContainer {
  margin-top: 10px;
  position: relative;
}

.contentContainerHome .clientesCont .clientesCarouselContainer #clientesCarousel div {
  float: left;
  line-height: 110px;
  text-align: center;
}

.contentContainerHome .clientesCont .clientesCarouselContainer #clientesCarousel div img {
  max-width: 100%;
  height: auto;
}

.contentContainerHome .clientesCont .clientesCarouselContainer #prevCliente,
.contentContainerHome .clientesCont .clientesCarouselContainer #nextCliente {
  position: absolute;
  top: 50%;
  color: #555;
  font-size: 32px;
  margin-top: -20px;
}

.contentContainerHome .clientesCont .clientesCarouselContainer #prevCliente {
  left: -30px;
}

.contentContainerHome .clientesCont .clientesCarouselContainer #nextCliente {
  right: -30px;
}

.redCarouselCont {
  position: relative;
}

.redCarouselCont .caroufredsel_wrapper {
  margin-top: 30px !important;
}

.redCarouselCont:after {
  content: '';
  display: block;
  height: 100%;
  width: 1px;
  right: 0;
  top: 0;
  background-color: #f7f7f7;
  position: absolute;
  z-index: 9;
}

.redCarouselCont #redCarousel {
  overflow: hidden;
  margin-top: 50px;
}

.redCarouselCont #redCarousel .redItem {
  position: relative;
  font-family: "Work Sans", sans-serif;
  float: left;
  text-align: center;
  border-right: solid 1px #979797;
  padding-top: 10px;
  padding-bottom: 10px;
}

.redCarouselCont #redCarousel .redItem p {
  margin-bottom: 0;
}

.redCarouselCont #redCarousel .redItem a {
  font-size: 18px;
  color: #353535;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.redCarouselCont #redCarousel .redItem a:hover {
  text-decoration: none;
  color: #FE8A52;
}

.redCarouselCont #redCarousel .redItem span {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: absolute;
  font-size: 15px;
  background-color: #fff;
  padding: 5px;
  border: solid 1px #ccc;
  left: 0;
  right: 0;
  top: 20px;
}

.redCarouselCont #prevRed,
.redCarouselCont #nextRed {
  position: absolute;
  top: 50%;
  color: #555;
  font-size: 32px;
  margin-top: -20px;
}

.redCarouselCont #prevRed {
  left: -30px;
}

.redCarouselCont #nextRed {
  right: -30px;
}

.breadcrumbs.page {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #2D2D2D;
  font-size: 11px;
  text-transform: uppercase;
  padding-top: 20px;
}

.breadcrumbs.page a {
  color: #2D2D2D;
  font-weight: 500;
}

.single-post .pageContent {
  padding-top: 10px;
}

.pageContent {
  padding-top: 30px;
  position: relative;
}

.pageContent:before {
  background-position: left top;
  background-repeat: no-repeat;
  content: '';
  display: block;
  width: 500px;
  height: 500px;
  position: absolute;
  z-index: -1;
  left: -40px;
  top: 10px;
}

.pageContent .imagenDestacada {
  width: 100%;
}

.pageContent blockquote {
  margin-bottom: 30px;
}

.pageContent blockquote p {
  max-width: inherit;
}

.pageContent p strong {
  font-weight: 800;
}

.page-id-39 .pageContent:before {
  background-image: url(../img/letter-r.png);
}

.page-id-16 .pageContent:before {
  background-image: url(../img/letter-q.png);
}

.page-id-18 .pageContent:before {
  background-image: url(../img/letter-n.png);
}

.page-id-11 .pageContent:before {
  background-image: url(../img/letter-c.png);
}

.page-id-9 .pageContent:before {
  background-image: url(../img/letter-p.png);
}

.newLetter:before {
  background-image: url(../img/letter-n.png);
}

y servicios .singleProdServConct .row:last-child .singleProdServ {
  margin-bottom: 0;
}

y servicios .singleProdServConct .singleProdServ {
  margin-bottom: 40px;
  overflow: hidden;
}

y servicios .singleProdServConct .singleProdServ.itemN01 {
  opacity: 1 !important;
}

y servicios .singleProdServConct .singleProdServ img {
  width: 100%;
}

y servicios .singleProdServConct .singleProdServ.even img {
  margin-left: auto;
}

y servicios .singleProdServConct .singleProdServ h3 {
  font-size: 36px;
  line-height: 36px;
  color: #2D2D2D;
}

y servicios .singleProdServConct .singleProdServ h3 strong {
  font-weight: 900;
}

y servicios .singleProdServConct .singleProdServ .singleProdServCont {
  padding-left: 20px;
}

y servicios .singleProdServConct .singleProdServ .singleProdServCont ul {
  list-style-type: none;
}

y servicios .singleProdServConct .singleProdServ .singleProdServCont ul li {
  padding-left: 40px;
  color: #575757;
  font-size: 18px;
  background-image: url(../img/list-item-ok.png);
  background-position: center left;
  padding-top: 10px;
  padding-bottom: 10px;
  background-repeat: no-repeat;
}

.landingCentrosCont .singleCentro {
  margin-bottom: 30px;
}

.landingCentrosCont .singleCentro .imgCont {
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
}

.landingCentrosCont .singleCentro .imgCont img {
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.landingCentrosCont .singleCentro .imgCont:hover a.aMask {
  background-color: rgba(0, 0, 0, 0.44);
}

.landingCentrosCont .singleCentro .imgCont:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.landingCentrosCont .singleCentro .imgCont:hover span {
  opacity: 1;
}

.landingCentrosCont .singleCentro .imgCont:hover h2 {
  opacity: 0;
}

.landingCentrosCont .singleCentro .imgCont span {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  display: block;
  left: 0;
  top: 50%;
  margin-top: -20px;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

.landingCentrosCont .singleCentro .imgCont span img {
  display: block;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.landingCentrosCont .singleCentro .imgCont h2 {
  color: #fff;
  font-family: "Muli", sans-serif;
  font-weight: 800;
  font-size: 26px;
  line-height: normal;
  left: 20px;
  bottom: 15px;
  position: absolute;
  z-index: 99;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.landingCentrosCont .singleCentro .imgCont h2 a {
  color: #fff;
}

.landingCentrosCont .singleCentro .imgCont h2 a:hover {
  text-decoration: none;
}

.landingCentrosCont .singleCentro .imgCont a.aMask {
  z-index: 9;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.33);
  margin: 0;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.landingCentrosCont .singleCentro .singleCaract {
  display: none;
  margin-bottom: 10px;
}

.landingCentrosCont .singleCentro .singleCaract.itemN01, .landingCentrosCont .singleCentro .singleCaract.itemN02 {
  display: block;
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.landingCentrosCont .singleCentro .singleCaract .itemN02 {
  min-height: 50px;
}

.landingCentrosCont .singleCentro .singleCaract img {
  display: block;
  margin-right: 10px;
  float: left;
}

.landingCentrosCont .singleCentro.itemN01 {
  opacity: 1 !important;
}

.landingCentrosCont .singleCentro.itemN02 {
  opacity: 1 !important;
}

.landingCentrosCont .singleCentro.itemN03 {
  opacity: 1 !important;
}

.centroContainer {
  background-image: url(../img/back-black.png);
  background-repeat: repeat-x;
  background-position: left top;
  padding-top: 20px;
}

.centroContainer .pageContent {
  padding-top: 0;
}

.centroContainer .pageContent:before {
  display: none;
}

.centroContainer ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.centroContainer .breadcrumbs {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  margin-top: 7px;
  margin-bottom: 20px;
}

.centroContainer .breadcrumbs a {
  color: #fff;
  font-weight: 400;
}

.centroContainer .backLink {
  font-size: 13px;
  color: #949494;
  text-transform: uppercase;
  font-family: "Muli", sans-serif;
  display: block;
  line-height: 13px;
}

.centroContainer .backLink i {
  font-size: 26px;
  vertical-align: middle;
}

.centroContainer .backLink:hover {
  text-decoration: none;
}

.centroContainer .caractContainer {
  margin-bottom: 10px;
}

.centroContainer .mainContent {
  margin-top: 30px;
  margin-bottom: 30px;
}

.centroContainer .mainContent .centDistCont {
  max-width: 800px;
}

.centroContainer .mainContent .centDistCont blockquote p {
  padding-left: 0;
}

.centroContainer .mainContent .centDistCont p {
  padding-left: 30px;
}

.centroContainer .singleCaract {
  padding-bottom: 20px;
  overflow: hidden;
}

.centroContainer .singleCaract img {
  display: block;
  float: left;
}

.centroContainer .singleCaract span {
  display: block;
  margin-left: 45px;
}

.centroContainer .singleCaract a {
  display: block;
  color: #FE8A52;
}

.centroContainer .singleCaract a:hover {
  text-decoration: none;
}

.centroContainer .singleCaract.itemN01, .centroContainer .singleCaract.itemN02, .centroContainer .singleCaract.itemN03 {
  border-right: solid 1px #ccc;
}

.centroContainer .singleCaract.itemN03 {
  padding-bottom: 0;
  margin-bottom: 20px;
}

.centroContainer .galCont {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.centroContainer .galCont li {
  margin-bottom: 30px;
}

.centroContainer .galCont li .singleItem {
  min-height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.centroContainer .galCont li .singleItem a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.centroContainer .singleSolucion {
  margin-bottom: 35px;
  font-size: 16px;
}

.centroContainer .singleSolucion img {
  margin-bottom: 30px;
}

.centroContainer .singleSolucion a {
  color: #ED1591;
  font-size: 14px;
  text-decoration: underline;
}

.centroContainer blockquote {
  color: #2D2D2D;
  line-height: normal;
  font-size: 18px;
}

.landingCentrosdeDistCont .singleCentroDeDist {
  overflow: hidden;
  margin-bottom: 30px;
}

.landingCentrosdeDistCont .singleCentroDeDist.itemN01 {
  opacity: 1 !important;
}

.landingCentrosdeDistCont .singleCentroDeDist.even .minInfoCont .minInfoMts {
  margin-right: 20px;
}

.landingCentrosdeDistCont .singleCentroDeDist.odd .minInfoCont .minInfoDire {
  margin-left: 20px;
}

.landingCentrosdeDistCont .singleCentroDeDist.odd .cambiaLugar .contCont {
  text-align: right;
}

.landingCentrosdeDistCont .singleCentroDeDist.odd .cambiaLugar .contCont img {
  margin-left: auto;
}

.landingCentrosdeDistCont .singleCentroDeDist.odd .cambiaLugar .contCont .contContHead {
  border-left: none;
  border-right: solid 2px #FE8A52;
  padding-left: 0;
  padding-right: 20px;
}

.landingCentrosdeDistCont .singleCentroDeDist .logoCliente {
  max-width: 115px;
  margin-bottom: 5px;
}

.landingCentrosdeDistCont .singleCentroDeDist.even .logoCliente {
  float: left;
}

.landingCentrosdeDistCont .singleCentroDeDist.odd .logoCliente {
  float: right;
}

.landingCentrosdeDistCont .contContHead {
  border-left: solid 2px #FE8A52;
  padding-left: 20px;
  margin-bottom: 20px;
}

.landingCentrosdeDistCont .contContHead h2 {
  font-size: 24px;
  line-height: normal;
  margin-bottom: 0;
  font-weight: bold;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.landingCentrosdeDistCont .contContHead h2 a {
  color: #2D2D2D;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.landingCentrosdeDistCont .contContHead h2 a:hover {
  text-decoration: none;
  color: #000;
}

.landingCentrosdeDistCont .contContHead strong {
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
  color: #2D2D2D;
  font-weight: bold;
}

.landingCentrosdeDistCont .minInfoCont {
  padding-bottom: 10px;
}

.landingCentrosdeDistCont .minInfoCont > div {
  display: inline-block;
}

.landingCentrosdeDistCont .minInfoCont .minInfoMts {
  padding: 8px 0 8px 40px;
  background-image: url(../img/icon-regla.png);
  background-position: left center;
  background-repeat: no-repeat;
}

.landingCentrosdeDistCont .minInfoCont .minInfoDire {
  padding: 8px 0 8px 35px;
  background-image: url(../img/icon-pin-black.png);
  background-position: left center;
  background-repeat: no-repeat;
}

.landingNewsCont .itemN04 {
  clear: left;
}

.landingNewsCont h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
}

.landingNewsCont h2 a {
  color: #2D2D2D;
}

.landingNewsCont h2 a:focus {
  text-decoration: none;
}

.landingNewsCont .singleNew {
  overflow: hidden;
}

.landingNewsCont .singleNew .fechaPublicacion {
  font-size: 12px;
  display: block;
}

.landingNewsCont .notDestacada .singleNew {
  margin-bottom: 30px;
}

.landingNewsCont .notDestacada .singleNew .contCont {
  padding-left: 30px;
  position: relative;
}

.landingNewsCont .notDestacada .singleNew .contCont:before {
  content: '';
  display: block;
  width: 2px;
  height: 77px;
  background-color: #FE8A52;
  position: absolute;
  left: 10px;
  top: 0;
}

.landingNewsCont .notDestacada .singleNew .contCont h2 {
  margin-bottom: 5px;
}

.landingNewsCont .notDestacada .singleNew .contCont h2 a:hover {
  text-decoration: none;
}

.landingNewsCont .notDestacada .singleNew .contCont .fechaPublicacion {
  font-style: italic;
  margin-bottom: 5px;
  color: #2D2D2D;
  margin-top: 5px;
}

.landingNewsCont .notDestacada .singleNew .contCont .autorPublicacion {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  display: block;
  color: #2D2D2D;
}

.landingNewsCont .notNormal .singleNew {
  margin-bottom: 30px;
}

.landingNewsCont .notNormal .singleNew .imgCont {
  position: relative;
  overflow: hidden;
}

.landingNewsCont .notNormal .singleNew .imgCont:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.landingNewsCont .notNormal .singleNew .imgCont img {
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.landingNewsCont .notNormal .singleNew .imgCont .contContHeader {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(91, 189, 232, 0.9);
  color: #fff;
  padding: 10px 20px;
}

.landingNewsCont .notNormal .singleNew .imgCont .contContHeader h2 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: normal;
}

.landingNewsCont .notNormal .singleNew .imgCont .contContHeader a {
  color: #fff;
}

.landingNewsCont .notNormal .singleNew .imgCont .contContHeader a:hover {
  text-decoration: none;
}

.landingNewsCont .notNormal .singleNew .imgCont .contContHeader .autorPublicacion {
  font-style: italic;
  font-size: 12px;
  font-weight: normal;
}

.landingNewsCont .notNormal .singleNew .contCont {
  padding: 10px 20px;
}

.insideNewCont .singleContContHeader {
  padding-left: 20px;
  position: relative;
}

.insideNewCont .singleContContHeader:before {
  content: '';
  display: block;
  width: 2px;
  height: 77px;
  background-color: #FE8A52;
  position: absolute;
  left: 0;
  top: 0;
}

.insideNewCont .singleContContHeader h2 {
  margin-bottom: 5px;
  min-height: 60px;
  font-size: 24px;
  color: #2D2D2D;
  line-height: normal;
  font-weight: bold;
}

.insideNewCont .singleContContHeader .fechaPublicacion {
  font-style: italic;
  margin-bottom: 5px;
  color: #2D2D2D;
  margin-top: 5px;
  font-size: 12px;
  line-height: normal;
  display: block;
}

.insideNewCont .singleContContHeader .autorPublicacion {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  display: block;
  color: #2D2D2D;
}

.insideNewCont .imgDestacadaContent {
  margin-bottom: 25px;
}

.wp-pagenavi {
  clear: both;
  text-align: center;
  font-size: 0;
  color: #5BBDE8;
}

.wp-pagenavi i {
  font-size: 26px;
  vertical-align: sub;
}

.wp-pagenavi .pages {
  display: none;
}

.wp-pagenavi span,
.wp-pagenavi a {
  font-size: 17px;
  font-weight: 500;
  display: inline-block;
  line-height: 30px;
  padding-left: 7px;
  padding-right: 7px;
  border: solid 1px #5BBDE8;
  margin-left: 4px;
  margin-right: 4px;
}

.wp-pagenavi a {
  color: #575757;
  border: solid 1px #fff;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.wp-pagenavi a:hover {
  text-decoration: none;
}

.wp-pagenavi a.page:hover {
  border: solid 1px #5BBDE8;
  background-color: #5BBDE8;
  color: #fff;
}

.contactDesc ul {
  font-size: 18px;
  color: #bebebe;
  list-style-type: none;
  font-weight: 300;
  margin-left: 30px;
  padding-top: 5px;
}

.contactDesc ul li {
  width: 250px;
  height: 45px;
  margin-left: 80px;
  margin-bottom: 20px;
  text-align: center;
  background-repeat: no-repeat;
  border: solid 1px #CFCFCF;
  border-radius: 8px;
}

.contactDesc ul li.linkNoStyle a {
  color: #CFCFCF;
  line-height: 45px;
}

.contactDesc ul li:first-child {
  background-size: 25px;
  line-height: 45px;
  color: #CFCFCF;
  background-position: 15px center;
  background-image: url(../img/contact-phone.png);
}

.contactDesc ul li:last-child {
  background-size: 25px;
  font-size: 12px;
  padding-top: 7px;
  line-height: normal;
  background-position: 15px center;
  background-image: url(../img/contact-pin.png);
}

.formContainer {
  font-size: 14px;
  color: #575757;
}

.formContainer .row {
  margin-bottom: 20px;
}

.formContainer .row.noBMargin {
  margin-bottom: 0;
}

.formContainer .row .colNombre:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 0;
  z-index: -1;
  background-image: url(../img/form-nombre.png);
  background-repeat: no-repeat;
  background-position: left top;
}

.formContainer .row .colEmail:before {
  content: '';
  display: block;
  width: 49px;
  height: 36px;
  position: absolute;
  left: -25px;
  top: 0;
  z-index: -1;
  background-image: url(../img/form-mail.png);
  background-repeat: no-repeat;
  background-position: left top;
}

.formContainer .row .colTelefono:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -15px;
  top: 0;
  z-index: -1;
  background-image: url(../img/form-telefono.png);
  background-repeat: no-repeat;
  background-position: left top;
}

.formContainer .row .labelForm.colTelefono {
  padding-left: 25px;
}

.formContainer .row .colMensaje:before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: 0;
  z-index: -1;
  background-image: url(../img/form-mensaje.png);
  background-repeat: no-repeat;
  background-position: left top;
}

.formContainer .row .labelForm {
  padding-left: 15px;
  padding-bottom: 5px;
  display: inline-block;
}

.formContainer .row .singleCampo,
.formContainer .row .textArea {
  width: 100%;
  padding: 5px 10px;
  border: solid 1px #E1E1E1;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.formContainer .row .singleCampo:focus,
.formContainer .row .textArea:focus {
  outline: none;
  border: solid 1px #FE8A52;
}

.formContainer .row .textArea {
  height: 155px;
  max-height: 155px;
  min-height: 155px;
  max-width: 100%;
  min-width: 100%;
  border: solid 1px #E1E1E1;
}

.formContainer .row small {
  color: #D0011B;
  font-size: 10px;
}

.formContainer .row .wpcf7-not-valid-tip {
  font-size: 12px;
  font-weight: bold;
  margin-top: 3px;
}

.formContainer .wpcf7-submit {
  background-color: #5BBDE8;
  border: solid 1px #5BBDE8;
  color: #fff;
  padding: 8px 25px;
  border-radius: 4px;
  margin: 0;
  font-family: "Muli", sans-serif;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

.formContainer .wpcf7-submit:hover, .formContainer .wpcf7-submit:focus {
  outline: none;
  color: #5BBDE8;
  background-color: #fff;
}

.formContainer .ajax-loader {
  display: none !important;
}

.formContainer .wpcf7-response-output {
  margin: 0;
  padding: 0;
}

.formContainer .wpcf7-validation-errors,
.formContainer .wpcf7-mail-sent-ok {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  margin-left: auto;
  text-align: center;
  background-color: #f00;
  color: #fff;
  border: none;
  font-size: 12px;
}

.formContainer .wpcf7-mail-sent-ok {
  background-color: #FE8A52;
}

.contactMapCont {
  padding-top: 30px;
}

#sidebar .sidebarTitle {
  color: #2D2D2D;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  min-height: 43px;
}

#sidebar .singleNew {
  margin-bottom: 35px;
}

#sidebar .singleNew .imgCont {
  margin-bottom: 10px;
  position: relative;
}

#sidebar .singleNew .imgCont .contContHeader {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(91, 189, 232, 0.9);
  color: #fff;
  padding: 10px;
}

#sidebar .singleNew .imgCont .contContHeader .fechaPublicacion {
  font-size: 12px;
}

#sidebar .singleNew .imgCont .contContHeader h3 {
  font-size: 18px;
  margin-bottom: 0;
}

#sidebar .singleNew .imgCont .contContHeader h3 a {
  color: #fff;
}

#sidebar .singleNew .imgCont .contContHeader .autorPublicacion {
  font-size: 12px;
}

.insideSidebar {
  margin-top: 38px;
}

.container404 {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.container404 h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 144px;
  font-weight: 800;
  color: #FE8A52;
  line-height: 136px;
}

.container404 h3 {
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 20px;
}

.container404 p {
  margin-bottom: 50px;
}

footer {
  padding-top: 40px;
  overflow: hidden;
  position: relative;
}

footer .section01 {
  background-color: #f7f7f7;
  padding-top: 25px;
  padding-bottom: 25px;
}

footer .section02 {
  background-color: #2D2D2D;
  padding-top: 25px;
  padding-bottom: 15px;
  color: #fff;
  font-family: "Muli", sans-serif;
}

footer .section02 .borderRight {
  border-right: solid 1px #575757;
  min-height: 150px;
}

footer .section02 #menu-menu-principal-2 {
  list-style-type: none;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 20px;
  padding: 0;
}

footer .section02 #menu-menu-principal-2 .itemN01 {
  font-weight: 600;
}

footer .section02 #menu-menu-principal-2 ul,
footer .section02 #menu-menu-principal-2 .showMovilSubMenu {
  display: none;
}

footer .section02 #menu-menu-principal-2 li {
  margin-bottom: 5px;
}

footer .section02 #menu-menu-principal-2 a {
  color: #CFCFCF;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  position: relative;
}

footer .section02 #menu-menu-principal-2 a:before {
  content: '';
  display: block;
  width: 5px;
  height: 9px;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  background-color: #CFCFCF;
  position: absolute;
  top: 2px;
  left: -14px;
  opacity: 0;
}

footer .section02 #menu-menu-principal-2 a:hover {
  color: #fff;
  text-decoration: none;
}

footer .section02 #menu-menu-principal-2 a:hover:before {
  left: -10px;
  opacity: 1;
}

footer .section02 .nav-tabs {
  border: none;
  margin-bottom: 20px;
  display: inline-block;
}

footer .section02 .nav-tabs li {
  margin: 0;
}

footer .section02 .nav-tabs li:first-child a {
  padding-left: 0;
}

footer .section02 .nav-tabs li a {
  border-left: solid 1px #575757;
  font-size: 18px;
  color: #C5C5C5;
  border-radius: 0;
  padding: 5px 30px;
  margin: 0;
  border-top: none;
  border-right: none;
  border-bottom: none;
  line-height: normal;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
}

footer .section02 .nav-tabs li a:hover, footer .section02 .nav-tabs li a:focus {
  outline: none;
  background: none;
  text-decoration: underline;
  border: none;
  border-left: solid 1px #575757;
  color: #fff;
}

footer .section02 .nav-tabs li.active a {
  background: none;
  text-decoration: underline;
  color: #fff;
}

footer .section02 .nav-tabs li:first-child a {
  border: none;
}

footer .section02 .tab-content {
  max-width: 360px;
  text-align: left;
}

footer .section02 .footerPin {
  background-image: url(../img/icon-pin.png);
  min-height: 36px;
  background-repeat: no-repeat;
  background-position: left top;
  padding-left: 35px;
  padding-bottom: 10px;
}

footer .section02 .footerClock {
  background-image: url(../img/icon-clock.png);
  min-height: 36px;
  background-repeat: no-repeat;
  background-position: left top;
  padding-left: 35px;
  padding-bottom: 10px;
}

footer .section02 .footerMail {
  background-image: url(../img/icon-mail-footer.png);
  min-height: 36px;
  background-repeat: no-repeat;
  background-position: left top;
  padding-left: 35px;
  padding-bottom: 10px;
}

footer .section02 .footerMail a {
  color: #fff;
  line-height: 24px;
}

footer .section02 .footerWeb {
  background-image: url(../img/icon-web-footer.png);
  min-height: 36px;
  background-repeat: no-repeat;
  background-position: left top;
  padding-left: 35px;
  padding-bottom: 10px;
}

footer .section02 .footerWeb a {
  color: #fff;
  line-height: 24px;
}

footer .section02 .phoneNumber {
  font-size: 16px;
}

footer .section02 .phoneNumber p,
footer .section02 .phoneNumber a {
  color: #C5C5C5;
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 18px;
  margin-right: 55px;
  text-decoration: none;
}

footer .section02 .phoneNumber span {
  display: inline-block;
  border: solid 2px #979797;
  border-radius: 8px;
  padding: 7px 20px;
}

footer .section02 .phoneNumber span i {
  font-size: 28px;
  margin-right: 10px;
  vertical-align: middle;
}

footer .firmaMitocondria {
  background-color: #999;
  border-radius: 3px 0px 0px 3px;
  right: -156px;
  bottom: 15px;
  width: 185px;
  cursor: pointer;
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -ms-transition: 1s all ease;
  z-index: 10;
  position: absolute;
}

footer .firmaMitocondria:hover {
  right: 0px;
}

footer .firmaMitocondria .texto p {
  color: #fff;
  display: block;
  float: right;
  margin-top: 5px;
  margin-bottom: 4px;
  font-family: "futura_bkbold";
  font-size: 9px;
  padding-right: 5px;
}

footer .firmaMitocondria .texto span {
  color: #fff;
  font-size: 9px;
}

footer .firmaMitocondria .texto .descpt {
  font-family: "futura_mdmedium";
}

footer .firmaMitocondria .imgFirma {
  display: block;
  float: left;
  width: 20px;
  margin-top: 0px;
  margin-left: 5px;
}

footer .firmaMitocondria .imgFirma img {
  width: 100%;
  max-width: 18px;
}

@media screen and (max-width: 1199px) {
  header h1,
  header h1 a {
    width: 100%;
  }
  header h1 a {
    background-size: contain;
  }
  header #menu-menu-principal-1 > li {
    margin-right: 30px;
  }
  .headerFixed #menu-menu-principal > li {
    margin-right: 25px;
  }
  header #menu-menu-principal-1 .sub-menu {
    background-image: none;
  }
  .contentContainerHome .sectionContent h2 {
    display: block;
    float: left;
    margin-right: 30px;
    padding-right: 30px;
  }
  .contentContainerHome .sectionContent div {
    display: block;
    margin-top: 10px;
  }
  .breadcrumbs.page {
    padding-bottom: 10px;
  }
  .pageContent {
    padding-top: 30px;
  }
  .prodServContainer .singleProdServ {
    min-height: 285px;
  }
  .contentContainerHome .content ul {
    margin-right: 85px;
  }
  .contentContainerHome .nuestroCentrosContainer:before,
  .contentContainerHome .centrosDistCont:before,
  .contentContainerHome .infraCont:before {
    left: -15px;
    top: -30px;
  }
  .pageContent:before {
    left: 0;
  }
  .contentContainerHome .nuestroCentrosContainer .item {
    background-position-x: 95%;
  }
  .contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav {
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    z-index: 999;
  }
  .contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav a {
    position: absolute;
  }
  .contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav a#prevCent {
    left: 0;
  }
  .contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav a#nextCent {
    right: 0;
  }
  .contentContainerHome .clientesCont .clientesCarouselContainer #prevCliente,
  .redCarouselCont #prevRed {
    left: -20px;
  }
  .contentContainerHome .clientesCont .clientesCarouselContainer #nextCliente,
  .redCarouselCont #nextRed {
    right: -20px;
  }
  .contactDesc ul li {
    margin-left: 50px;
  }
  .page-template-quienes-somos .mainContent blockquote {
    max-width: 460px;
    margin-top: 25px;
  }
  .page-template-quienes-somos .mainContent img {
    max-width: 460px;
    height: auto;
    margin-top: 25px;
  }
  .page-template-quienes-somos .aboutSecundario.itemN02 div {
    right: inherit;
    left: 0;
  }
  .page-template-quienes-somos .aboutSecundario.itemN03 img {
    margin-left: 0;
  }
  .page-template-quienes-somos .aboutSecundario.itemN03 div {
    right: 35px;
  }
}

@media screen and (min-width: 992px) {
  .landingCentrosCont .colN01 {
    clear: left;
  }
}

@media screen and (max-width: 991px) {
  .headerFixed,
  .contactTab,
  .contactTabContainer {
    display: none !important;
  }
  .mailAndMenu {
    margin-top: 10px;
  }
  .mailAndMenu span {
    vertical-align: middle;
    font-size: 34px;
    color: #979797;
    border-left: solid 1px #ddd;
    margin-left: 6px;
    padding-left: 10px;
    display: inline-block;
    text-align: center;
    width: 35px;
  }
  .mailAndMenu img {
    max-width: 20px;
  }
  .carouselContainer .item h2 {
    font-size: 48px;
  }
  .carouselContainer .item .container {
    max-height: 400px;
  }
  .contentContainerHome .content ul {
    margin-right: 150px;
  }
  .contentContainerHome .centrosDistCont .centCarouselCont .centCarouselNav {
    top: 80px;
  }
  .contentContainerHome .sectionContent div {
    margin-top: 0;
  }
  .contentContainerHome .nuestroCentrosContainer .item01 .item {
    background-size: 50%;
  }
  .contentContainerHome .nuestroCentrosContainer .item02 .item {
    background-size: 14%;
  }
  .contentContainerHome .nuestroCentrosContainer .item03 .item {
    background-size: 55%;
  }
  .contactDesc ul li {
    margin-left: 25px;
  }
  .formContainer .row .colNombre:before,
  .formContainer .row .colEmail:before,
  .formContainer .row .colMensaje:before {
    left: -15px;
  }
  .formContainer .row .colTelefono:before {
    left: -5px;
  }
  .formContainer .row .labelForm {
    padding-left: 25px;
  }
  .formContainer .row .labelForm.colTelefono {
    padding-left: 35px;
  }
  .page-template-quienes-somos .mainContent blockquote {
    max-width: 100%;
  }
  .page-template-quienes-somos .mainContent img {
    max-width: inherit;
    float: none;
    display: block;
    margin: 0 auto;
  }
  .page-template-quienes-somos .aboutSecundario {
    margin-bottom: 30px;
  }
  .page-template-quienes-somos .aboutSecundario img {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }
  .page-template-quienes-somos .aboutSecundario.itemN03 {
    padding-bottom: 0;
  }
  .page-template-quienes-somos .aboutSecundario.itemN01 div,
  .page-template-quienes-somos .aboutSecundario.itemN02 div,
  .page-template-quienes-somos .aboutSecundario.itemN03 div {
    position: relative;
    text-align: left;
    left: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
    width: 100%;
    margin: 0;
  }
  .page-template-quienes-somos .aboutSecundario.itemN02 div span {
    top: 0;
  }
  .page-template-quienes-somos .aboutSecundario.itemN03 div span {
    right: 0;
    bottom: 0;
  }
  .prodServContainer .singleProdServ {
    min-height: 200px;
  }
  .singleProdServ img {
    margin-bottom: 10px;
    max-width: 500px;
    margin-left: 0 !important;
  }
  .centroContainer .galCont li .singleItem {
    min-height: 225px;
  }
  .landingCentrosCont .even {
    clear: left;
  }
  footer {
    padding-top: 30px;
  }
  footer .section02 {
    padding-top: 25px;
  }
  footer .section02 .borderRight {
    border: none;
  }
  footer .section02 .nav-tabs,
  footer .section02 .tab-content {
    display: block;
    max-width: 100%;
  }
  .col-xs-12.col-sm-8.col-md-6.borderRight.text-center {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  header {
    padding-top: 0;
    padding-bottom: 0;
  }
  .breadcrumbs,
  .backLink,
  .contentContainerHome .centrosDistCont:before,
  .contentContainerHome .infraCont:before,
  .contentContainerHome .nuestroCentrosContainer:before,
  .page-id-16 .pageContent:before,
  .page-id-39 .pageContent:before,
  .page-id-18 .pageContent:before,
  .newLetter:before,
  .pageContent:before {
    display: none !important;
  }
  .centroContainer .singleCaract.itemN01,
  .centroContainer .singleCaract.itemN02,
  .centroContainer .singleCaract.itemN03 {
    border: none;
  }
  .pageContent {
    padding-top: 0;
  }
  .minRightPadding {
    padding-right: 10px;
  }
  .minLeftPadding {
    padding-left: 0px;
  }
  .contentContainerHome {
    overflow: hidden;
  }
  .carouselContainer .item .carouselItem {
    bottom: 0;
  }
  .contentContainerHome .sectionContent h2 {
    float: none;
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    margin-top: 0;
    margin-bottom: 10px !important;
  }
  .contentContainerHome .content ul {
    margin-right: 0;
    clear: both;
  }
  .page-template-quienes-somos .aboutSecundario span {
    display: none;
  }
  .page-template-quienes-somos .mainContent img {
    width: 100%;
  }
  .page-template-quienes-somos .aboutSecundario div {
    padding: 15px 0 0 !important;
  }
  .centroContainer {
    background: none;
    padding-top: 0;
  }
  .centroContainer .mainContent .centDistCont p {
    padding-left: 19px;
  }
  .centroContainer .solucionesContainer .row {
    position: relative;
  }
  .centroContainer .solucionesContainer .row .singleSolucion {
    text-align: center;
    margin-bottom: 10px;
  }
  .centroContainer .solucionesContainer .row .singleSolucion img {
    margin-left: auto;
    margin-right: auto;
  }
  .centroContainer .solucionesContainer .row .solCarouselNav a {
    position: absolute;
    top: 110px;
    font-size: 32px;
    color: #2D2D2D;
    z-index: 99999;
  }
  .centroContainer .solucionesContainer .row .solCarouselNav a#prevSolucion {
    left: 20px;
  }
  .centroContainer .solucionesContainer .row .solCarouselNav a#nextSolucion {
    right: 20px;
  }
  .contentContainerHome .clientesCont .clientesCarouselContainer #prevCliente,
  .redCarouselCont #prevRed {
    left: 0px;
  }
  .contentContainerHome .clientesCont .clientesCarouselContainer #nextCliente,
  .redCarouselCont #nextRed {
    right: 0px;
  }
  blockquote {
    padding-right: 0;
    padding-left: 15px;
  }
  .pageTitle,
  .singleProdServConct .singleProdServ h3 {
    font-size: 24px;
    line-height: normal;
  }
  .pageTitle br,
  .singleProdServConct .singleProdServ h3 br {
    display: none;
  }
  .pageTitle span,
  .singleProdServConct .singleProdServ h3 span {
    display: inline;
  }
  .singleProdServ h3 p {
    margin-bottom: 0;
  }
  .pageTitle {
    margin-bottom: 20px !important;
    margin-top: 10px;
  }
  .pageTitle:before {
    content: '';
    display: block;
    width: 10px;
    height: 25px;
    background-color: #2D2D2D;
    position: absolute;
    left: -15px;
    top: 2px;
  }
  .contentContainerHome section {
    margin-bottom: 30px;
  }
  .contentContainerHome .pageTitle {
    line-height: normal;
  }
  .contentContainerHome .content {
    margin-left: 0;
    line-height: 1.5em;
    font-size: 15px;
  }
  .contentContainerHome section.centrosDistCont .centCarouselCont {
    position: relative;
  }
  .contentContainerHome section.centrosDistCont .centCarouselCont .contCont {
    padding-left: 15px;
    padding-right: 15px;
  }
  .contentContainerHome section.centrosDistCont .centCarouselCont #centCarousel > div {
    padding-left: 0;
    padding-right: 0;
  }
  .contentContainerHome section.centrosDistCont .centCarouselCont .centCarouselNav {
    position: absolute;
    top: 50%;
    margin-top: -42px;
    z-index: 99999;
    width: 100%;
  }
  .contentContainerHome section.centrosDistCont .centCarouselCont .centCarouselNav #prevCent {
    position: absolute;
    left: 0;
  }
  .contentContainerHome section.centrosDistCont .centCarouselCont .centCarouselNav #nextCent {
    position: absolute;
    right: 0;
  }
  .contentContainerHome .nuestroCentrosContainer .item01,
  .contentContainerHome .nuestroCentrosContainer .item02,
  .contentContainerHome .nuestroCentrosContainer .item03 {
    padding: 0;
  }
  .contentContainerHome .nuestroCentrosContainer .item01 .item,
  .contentContainerHome .nuestroCentrosContainer .item02 .item,
  .contentContainerHome .nuestroCentrosContainer .item03 .item {
    overflow: hidden;
    min-height: 190px;
  }
  .contentContainerHome .nuestroCentrosContainer .item01 .item h3,
  .contentContainerHome .nuestroCentrosContainer .item02 .item h3,
  .contentContainerHome .nuestroCentrosContainer .item03 .item h3 {
    margin-bottom: 30px;
  }
  .contentContainerHome .nuestroCentrosContainer .item01 .item {
    background-position-y: 30px;
    background-size: 150px;
  }
  .contentContainerHome .nuestroCentrosContainer .item02 .item {
    background-position-y: 8px;
    background-size: 35px;
    background-position-x: 88%;
  }
  .contentContainerHome .nuestroCentrosContainer .item03 .item {
    background-position-y: 20px;
    background-size: 150px;
  }
  .contentContainerHome .nuestroCentrosContainer .dropdownMovil {
    background-color: #EBEBEB;
  }
  .contentContainerHome .nuestroCentrosContainer .dropdownMovil .despliegaDropMovil {
    font-weight: 600;
    margin-bottom: 0;
    border-top: solid 1px #CFCFCF;
    border-bottom: solid 1px #CFCFCF;
    padding: 15px 25px;
    position: relative;
    z-index: 999;
  }
  .contentContainerHome .nuestroCentrosContainer .dropdownMovil .despliegaDropMovil .caret {
    position: absolute;
    right: 20px;
    top: 50%;
  }
  .contentContainerHome .nuestroCentrosContainer .dropdownMovil ul {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    list-style-type: none;
    display: none;
    background-color: #f3f3f3;
  }
  .contentContainerHome .nuestroCentrosContainer .dropdownMovil ul a {
    color: #575757;
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
    -webkit-transition: all ease-in-out .2s;
    transition: all ease-in-out .2s;
  }
  .contentContainerHome .nuestroCentrosContainer .dropdownMovil ul a:hover, .contentContainerHome .nuestroCentrosContainer .dropdownMovil ul a:focus {
    text-decoration: none;
    color: #000;
  }
  .landingCentrosdeDistCont .singleCentroDeDist .logoCliente {
    margin-top: 10px;
  }
  .landingCentrosdeDistCont .singleCentroDeDist .contContHead {
    padding-left: 15px;
  }
  .landingCentrosdeDistCont .singleCentroDeDist .contContHead h2 {
    font-size: 22px;
    line-height: normal;
  }
  .landingCentrosdeDistCont .singleCentroDeDist .minInfoCont .minInfoMts {
    margin-bottom: 10px;
    display: block;
  }
  .pageContent blockquote {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 1.4em;
  }
  .pageContent .mainContent {
    margin-top: 15px;
  }
  .pageContent .mainContent .logoCliente {
    max-width: 100px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .pageContent h2 {
    margin-bottom: 15px;
    font-size: 25px;
    text-align: left;
    line-height: normal;
    color: #333333;
    position: relative;
  }
  .pageContent img {
    max-width: 100%;
    height: auto;
  }
  .carouselContainer .carousel-indicators {
    right: 0;
  }
  .carouselContainer .item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .carouselContainer .item h2 {
    font-size: 25px;
  }
  .carouselContainer .item p {
    font-size: 16px;
  }
  .prodServContainer {
    margin-bottom: 25px;
  }
  .prodServContainer .singleProdServ {
    max-height: 140px;
    min-height: 140px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .prodServContainer .singleProdServ.itemN01 .mask {
    background-color: rgba(237, 21, 145, 0.5);
  }
  .prodServContainer .singleProdServ.itemN02 .mask {
    background-color: rgba(74, 144, 226, 0.5);
  }
  .prodServContainer .singleProdServ.itemN03 .mask {
    background-color: rgba(255, 135, 0, 0.5);
  }
  .prodServContainer .singleProdServ h3 {
    font-size: 20px;
    margin-top: 0 !important;
  }
  .prodServContainer .singleProdServ h3 br {
    display: none;
  }
  .prodServContainer .singleProdServ div {
    padding-left: 0;
    padding-right: 0;
  }
  .prodServContainer .singleProdServ div div {
    display: none;
  }
  .prodServContainer .singleProdServ h3 a i {
    font-size: 48px;
  }
  .singleProdServConct .singleProdServ .singleProdServCont {
    padding-left: 0;
  }
  .redCarouselCont #redCarousel .redItem {
    border: none;
  }
  .singleProdServConct .singleProdServ .singleProdServCont ul {
    line-height: 1.5em;
    font-size: 15px;
  }
  .landingCentrosCont .singleCentro .imgCont h2 {
    left: 15px;
    margin-bottom: 0;
  }
  .centroContainer .galCont li {
    margin-bottom: 15px;
  }
  .centroContainer .galCont li .singleItem {
    min-height: initial;
  }
  .centrosGaleria {
    position: relative;
  }
  .centrosGaleria .caroufredsel_wrapper {
    margin-left: 0px !important;
  }
  .centrosGaleria .navGalCar {
    position: absolute;
    top: 50%;
    font-size: 32px;
    color: #fff;
    margin-top: -20px;
  }
  .centrosGaleria #prevGalCar {
    left: 15px;
  }
  .centrosGaleria #nextGalCar {
    right: 15px;
  }
  .landingNewsCont .notDestacada .imgCont img {
    margin-bottom: 10px;
  }
  .landingNewsCont .notDestacada .singleNew .contCont {
    padding-left: 20px;
  }
  .landingNewsCont .notDestacada .singleNew .contCont:before {
    left: 0;
    top: 8px;
    height: 100px;
  }
  .landingNewsCont .notNormal .singleNew .imgCont .contContHeader {
    position: relative;
    left: initial;
    bottom: initial;
    padding: 10px 15px;
  }
  .landingNewsCont .notNormal .singleNew .imgCont .contContHeader h2 {
    font-size: 17px;
  }
  .landingNewsCont .notNormal .singleNew .contCont {
    display: none;
  }
  .contactDesc ul {
    margin-left: 0;
  }
  .contactDesc ul li {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .formContainer .row {
    margin-bottom: 15px;
  }
  .formContainer .row .labelForm {
    text-align: left;
    display: block;
  }
  .formContainer .row br {
    display: none;
  }
  .formContainer .row .labelForm {
    padding: 0;
    margin-bottom: 5px;
  }
  .formContainer .row .labelForm.colTelefono {
    margin-top: 15px;
    padding: 0;
  }
  .formContainer .row .labelForm:before {
    display: none;
  }
  footer {
    padding-top: 20px;
  }
  footer .section01 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  footer .section02 {
    padding-top: 30px;
  }
  footer .section02 .callBton {
    display: block;
    width: 255px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    border: solid 1px #fff;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-size: 18px;
    margin-bottom: 30px;
    display: block;
  }
  footer .section02 .nav-tabs li a {
    font-size: 16px;
    padding: 5px 17px !important;
  }
  footer .section02 .tab-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  footer .section02 .footerClock,
  footer .section02 .footerPin {
    font-size: 13px;
  }
  footer .redCarouselCont .caroufredsel_wrapper {
    margin-top: 20px !important;
  }
}

@media screen and (min-height: 700px) {
  .landingCentrosdeDistCont .singleCentroDeDist.itemN02 {
    opacity: 1 !important;
  }
}

@media screen and (min-height: 767px) {
  .singleProdServConct .singleProdServ.itemN02 {
    opacity: 1 !important;
  }
}

.singleProdServ {
  margin: 30px 0;
  float: left;
}
